import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor() { }

    getToken(): String {
        return window.sessionStorage['AccessToken'];
    }

    saveToken(token: String) {
        this.destroyToken();
        //window.localStorage['AccessToken'] = token;
        window.sessionStorage['AccessToken'] = token;
    }

    destroyToken() {
        window.sessionStorage.removeItem('AccessToken');
    }

    getUserId() {
        return parseInt(window.localStorage['UserId']);
    }
}
