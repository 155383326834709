import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CryptoService } from './crypto.service';
import { TokenService } from './token.service';
import { BnNgIdleService } from 'bn-ng-idle';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    logoutUser: boolean = true;
    timeoutValue: number = 0;

    constructor(private router: Router, private token: TokenService, private hashValDataService: CryptoService, private bnIdle: BnNgIdleService, private accountService: AccountService) {
        this.logoutUser = true;
        this.sessionTimeoutFn();
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.token.getToken() != null) {
            var path = window.location.pathname;
            var profileId = window.localStorage.getItem("UserProfileId");
            var p2 = this.hashValDataService.DserializeHashData(window.localStorage.getItem("_nav_SRXTT3C1W11"));
            var p3 = this.hashValDataService.DserializeHashData(window.localStorage.getItem(".AspNetCore.CIPxyU"));

            if ((profileId == "1" && p2 == "1" && p3 == "1") && (path == "/" || path == "/login" || path == "/client" || path == "/client/user" || path == "/client/geo" || path == "/client/cm" || path == "/client/rec"))//--superuser
            {
                return true;
            }
            else if (((profileId == "4" && p2 == "4" && p3 == "4") || (profileId == "8" && p2 == "8" && p3 == "8")) && (path == "/" || path == "/login" || path == "/dashboard/AG" || path == "/dashboard/SE" || path == "/dashboard/CAC" || path == "/dashboard/CC" || path == "/dashboard/CE" || path == "/demolive/livehome" || path == "/demo/demowmap" || path == "/demo/demohome" || path == "/demo/demoppage")) {//--4 Rep --8 Manager

                if (profileId == "8" && (path == "/demolive/livehome" || path == "/demo/demowmap" || path == "/demo/demohome" || path == "/demo/demoppage")) {
                    this.unauthorizedLogout();
                }
                return true;
            }
            else if (((profileId == "5" && p2 == "5" && p3 == "5") || (profileId == "9" && p2 == "9" && p3 == "9")) && (path == "/" || path == "/login" || path == "/coach/home" || path == "/coach/callanalysis" || path == "/coach/reports")) {//--5 SuperCoach --9 Coach
                if (profileId == "9" && path == "/coach/reports") {
                    this.unauthorizedLogout();
                }
                return true;
            }
            else if (((profileId == "6" && p2 == "6" && p3 == "6") || (profileId == "7" && p2 == "7" && p3 == "7")) && (path == "/" || path == "/login" || path == "/dashboard/OA" || path == "/dashboard/SS" || path == "/dashboard/CI" || path == "/dashboard/AG" || path == "/dashboard/SE" || path == "/dashboard/CAC" || path == "/dashboard/CC" || path == "/dashboard/CE")) {//--6 ISLSM --7 NSLSM
                return true;
            }
            else if ((profileId == "10" && p2 == "10" && p3 == "10") && (path == "/" || path == "/login" || path == "/client/rec")) { //--10 Call Analytic
                return true;
            }
            else if ((profileId == "3" && p2 == "3" && p3 == "3") && (path == "/" || path == "/login" || path == "/flsm/map" || path == "/flsm/home" || path == "/flsm/cot")) {//--3 flsm
                return true;
            }
            else if ((profileId == "2" && p2 == "2" && p3 == "2") && (path == "/" || path == "/login" || path == "/slsm/wmap" || path == "/slsm/home" || path == "/slsm/cot" || path == "/slsm/ppage")) {//--2 slsm
                return true;
            }
            else {
                this.unauthorizedLogout();
            }
        }
        this.token.destroyToken();
        this.router.navigate(['/login']);
        return false;
    }

    canLoad(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.token.getToken() != null) {
            var path = window.location.pathname;
            var profileId = window.localStorage.getItem("UserProfileId");
            var p2 = this.hashValDataService.DserializeHashData(window.localStorage.getItem("_nav_SRXTT3C1W11"));
            var p3 = this.hashValDataService.DserializeHashData(window.localStorage.getItem(".AspNetCore.CIPxyU"));

            if ((profileId == "1" && p2 == "1" && p3 == "1") && (path == "/" || path == "/login" || path == "/client" || path == "/client/user" || path == "/client/geo" || path == "/client/cm" || path == "/client/rec"))//--superuser
            {
                return true;
            }
            else if (((profileId == "4" && p2 == "4" && p3 == "4") || (profileId == "8" && p2 == "8" && p3 == "8")) && (path == "/" || path == "/login" || path == "/dashboard/AG" || path == "/dashboard/SE" || path == "/dashboard/CAC" || path == "/dashboard/CC" || path == "/dashboard/CE" || path == "/demolive/livehome" || path == "/demo/demowmap" || path == "/demo/demohome" || path == "/demo/demoppage")) {//--4 Rep --8 Manager

                if (profileId == "8" && (path == "/demolive/livehome" || path == "/demo/demowmap" || path == "/demo/demohome" || path == "/demo/demoppage")) {
                    this.unauthorizedLogout();
                }
                return true;
            }
            else if (((profileId == "5" && p2 == "5" && p3 == "5") || (profileId == "9" && p2 == "9" && p3 == "9")) && (path == "/" || path == "/login" || path == "/coach/home" || path == "/coach/callanalysis" || path == "/coach/reports")) {//--5 SuperCoach --9 Coach
                if (profileId == "9" && path == "/coach/reports") {
                    this.unauthorizedLogout();
                }
                return true;
            }
            else if (((profileId == "6" && p2 == "6" && p3 == "6") || (profileId == "7" && p2 == "7" && p3 == "7")) && (path == "/" || path == "/login" || path == "/dashboard/OA" || path == "/dashboard/SS" || path == "/dashboard/CI" || path == "/dashboard/AG" || path == "/dashboard/SE" || path == "/dashboard/CAC" || path == "/dashboard/CC" || path == "/dashboard/CE")) {//--6 ISLSM --7 NSLSM
                return true;
            }
            else if ((profileId == "10" && p2 == "10" && p3 == "10") && (path == "/" || path == "/login" || path == "/rec")) { //--10 Call Analytic
                return true;
            }
            else if ((profileId == "3" && p2 == "3" && p3 == "3") && (path == "/" || path == "/login" || path == "/flsm/map" || path == "/flsm/home" || path == "/flsm/cot")) {//--3 flsm
                return true;
            }
            else if ((profileId == "2" && p2 == "2" && p3 == "2") && (path == "/" || path == "/login" || path == "/slsm/wmap" || path == "/slsm/home" || path == "/slsm/cot" || path == "/slsm/ppage")) {//--2 slsm
                return true;
            }
            else {
                this.unauthorizedLogout();
            }
        }
        this.token.destroyToken();
        this.router.navigate(['/login']);
        return false;
    }

    sessionTimeoutFn() {
        if (localStorage.getItem('_s1sd_vtimeo1XeeeNN54__Xz') != undefined && localStorage.getItem('_s1sd_vtimeo1XeeeNN54__Xz') != null) {
            this.timeoutValue = parseInt(localStorage.getItem('_s1sd_vtimeo1XeeeNN54__Xz'));
            if (this.timeoutValue > 5) {
                this.timeoutValue = (this.timeoutValue - 5) * 60;
            }
        }
        else {
            return;
        }

        this.bnIdle.startWatching(this.timeoutValue).subscribe((isTimedOut: boolean) => {
            if (isTimedOut == true) {
                //if (window.location.pathname != "/login" && window.location.pathname != "/Reset-password") {
                Swal.fire({
                    title: 'Session Expire Warning!',
                    html: `<div>
                                <p>Your session will expire in next <b id="timer">00:00</b> minutes. Do you want to extend your session?</p>
                           </div> `,
                    showCancelButton: true,
                    confirmButtonText: 'No',
                    cancelButtonText: 'Yes',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        const timerElement = document.getElementById('timer');
                        let remainingTime = 5 * 60; // 5 minutes in seconds
                        const timerInterval = setInterval(() => {
                            remainingTime--;
                            const minutes = Math.floor(remainingTime / 60);
                            const seconds = remainingTime % 60;
                            timerElement!.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                            if (remainingTime <= 0) {
                                clearInterval(timerInterval);
                                Swal.close();
                                this.logoutUserFn();
                                this.logoutUser = true;
                            }
                        }, 1000);
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        var objParam = {
                            Token: window.sessionStorage['AccessToken'],
                            SystemType: "Web"
                        }
                        this.accountService.logoutUser(objParam).subscribe((result: any) => {
                            if (result.code == 200) {
                                this.router.navigate(['/login'])
                                    .then(() => {
                                        window.location.reload();
                                    });
                            }
                        }, error => console.error(error));
                    }
                    else if (result.dismiss === Swal.DismissReason.cancel) {
                        // Show another popup
                        Swal.fire({
                            icon: "success",
                            title: "Session extended.",
                            showConfirmButton: false,
                            timer: 2000
                        });
                        this.logoutUser = false;
                        this.bnIdle.resetTimer();
                    }
                });
                //}
            }
        });
    }

    logoutUserFn() {
        if (this.logoutUser == true) {
            Swal.fire({
                title: "Session expired.",
                showConfirmButton: true
            }).then((result) => {
                var objParam = {
                    Token: window.sessionStorage['AccessToken'],
                    SystemType: "Web"
                }
                this.accountService.logoutUser(objParam).subscribe((result: any) => {
                    if (result.code == 200) {
                        this.router.navigate(['/login'])
                            .then(() => {
                                window.location.reload();
                            });
                    }
                }, error => console.error(error));
            });
        }
    }

    unauthorizedLogout() {
        var objParam = {
            Token: window.sessionStorage['AccessToken'],
            SystemType: "Web"
        }
        this.accountService.logoutUser(objParam).subscribe((result: any) => {
            if (result.code == 200) {
                this.token.destroyToken();
                this.router.navigate(['/login']);
                return false;
            }
        }, error => console.error(error));
    }
}
