import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ClientManagementComponent } from './cms/client-management/client-management.component';
//import { LoginComponent } from './account/login/login.component';
//import { ClientManagementComponent } from './cms/client-management/client-management.component';
//import { SellingskillsComponent } from './dashboard/sellingskills/sellingskills.component';
//import { HomeComponent } from './slsm/home/home.component';
const routes: Routes = [
    {
        path: "",
        loadChildren: './account/account.module#AccountModule',

    },
    {
        path: "login",
        loadChildren: './account/account.module#AccountModule',
    },
    {
        path: "client",
        loadChildren: './cms/cms.module#CMSModule',
        canLoad: [AuthGuardService]
    },

    {
        path: "dashboard",
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canLoad: [AuthGuardService]
    },
    {
        path: "demo",
        loadChildren: './demo/demo.module#DemoModule',
        canLoad: [AuthGuardService]
    },
    {
        path: "demolive",
        loadChildren: './demolive/demolive.module#DemoliveModule',
        canLoad: [AuthGuardService]
    },
    {
        path: "flsm",
        loadChildren: './flsm/flsm.module#FlsmModule',
        canLoad: [AuthGuardService]
    },
    {
        path: "slsm",
        loadChildren: './slsm/slsm.module#SLSMModule',
        canLoad: [AuthGuardService]
    },
    {
        path: "coach",
        loadChildren: './coach/coach.module#CoachModule',
        canLoad: [AuthGuardService]
    },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
