import { Injectable } from '@angular/core';
import * as CryptoJs from 'crypto-js';
import CryptoKeyConfg from '../varC1abry1ddptoZbb';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {

    constructor() { }

    SerializeHashData(data) {
        return CryptoJs.AES.encrypt(data, CryptoKeyConfg.Zcry1arValCryGpc1oTpm).toString();
    }

    DserializeHashData(value) {
        if (value) {
            const decryptedWord = CryptoJs.AES.decrypt(value, CryptoKeyConfg.Zcry1arValCryGpc1oTpm);
            return decryptedWord.toString(CryptoJs.enc.Utf8);
        }
    }
}
